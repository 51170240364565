import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import "./index.scss";
import "./howto.scss";

export default () => (
  <Layout>
    <div className="background container-fluid">
      <AniLink cover bg="#ec277e" to="/" className="back-button">
        Back
      </AniLink>

      <div className="container-fix">
        <div className="row">
          <div className="col">
            <h1>How to use NotNow ?</h1>
            <p>
              NotNow is a simple email service that will snooze your emails.
              Send your emails to in3hours@notnow.co and you'll receive it back
              in 3 hours for example.
            </p>

            <h2>Working commands</h2>
            <p>NotNow understands a lot of commands, here is a list of them.</p>
            <h3>In some time</h3>
            <p>
              Use email addresses starting with "in" to simply snooze an email.
              If you send something to those addresses, the email will be send X
              minutes, hours or days after.
              <br />
              Here the working units of time:
            </p>
            <ul>
              <li>inXminutes@notnow.co</li>
              <li>inXhours@notnow.co</li>
              <li>inXdays@notnow.co</li>
              <li>inXweeks@notnow.co</li>
              <li>inXmonths@notnow.co</li>
            </ul>
            <p>
              Just replace X with the desired amount of time. For example, if
              you want to receive your email back in 3 hours, send it to
              in3hours@notnow.co.
            </p>

            <h3>On a specific day</h3>
            <p>
              To receive the email back on a specific day, use one of the
              following address:
            </p>
            <ul>
              <li>Monday: onmonday@notnow.co</li>
              <li>Tuesday: ontuesday@notnow.co</li>
              <li>Wednesday: onwednesday@notnow.co</li>
              <li>Thursday: onthursday@notnow.co</li>
              <li>Friday: onfriday@notnow.co</li>
              <li>Saturday: onsaturday@notnow.co</li>
              <li>Sunday: onsunday@notnow.co</li>
            </ul>

            <h3>The next day, week, month</h3>
            <p>
              To receive the email back at the begining of the next day, week,
              or month, use one of the following:
            </p>
            <ul>
              <li>nextday@notnow.co</li>
              <li>nextweek@notnow.co</li>
              <li>nextmonth@notnow.co</li>
            </ul>

            <h2>Use cases</h2>
            <p>
              NotNow can be used in many situations, here are some use cases
              reported by our users:
            </p>
            <ul>
              <li>
                You sent a message to a prospect and want to follow in 5 days:
                Just CC in5days@notnow.co
              </li>
              <li>
                You have a bill to pay next month: Simply forward your message
                to nextmonth@notnow.co
              </li>
              <li>
                You receive an email on your phone and want to read it on your
                computer when arriving at the office: Simply forward your
                message to in2hours@notnow.co
              </li>
              <li>
                You want to cancel your free Netflix subscription: Forward the
                welcome email to in29days@notnow.co
              </li>
            </ul>

            <p>
              You have another example of usage ? Send them to
              nicolas@notnow.co, I'll add them here.
            </p>

            <h2>What about the privacy of my messages ?</h2>
            <p>
              When you send an email to our service, we store it until we send
              it back to you. We don't store anything past that time, we don't
              need it! Emails are only sent back to the email address used to
              send an email. For example, if john@doe.com sends an email to
              in5hours@notnow.co, the email is stored for 5hours, then sent back
              to john@doe.com and deleted from our servers.
            </p>
            <p>
              We don't store any list of email addresses, usage or anything
              else, except the emails for the time you decided.
            </p>

            <hr />
            <footer>
              NotNow is a free tool developed by{" "}
              <a href="https://gp2mv3.com" target="_blank">
                Nicolas Cognaux
              </a>
              . Don't hesitate to contact me with your feedback!
            </footer>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
